import Cookie from 'js-cookie';
import { message } from 'antd';
import { gaPageviewTimingTitle, getEpochTime } from '../../../../helpers/utils';
export const ORDER_REPORTS = 'cds/order-reports/ORDER_REPORTS';
export const ORDER_REPORTS_DROPDOWN_DATA_SUCCESS =
  'cds/order-reports/ORDER_REPORTS_DROPDOWN_DATA_SUCCESS';
export const ORDER_REPORTS_DROPDOWN_DATA_FAIL =
  'cds/order-reports/ORDER_REPORTS_DROPDOWN_DATA_FAIL';
export const LOAD_CHART_DATA_SUCCESS =
  'cds/order-reports/LOAD_CHART_DATA_SUCCESS';
export const LOAD_CHART_DATA_FAIL = 'cds/order-reports/LOAD_CHART_DATA_FAIL';

const initialState = {
  newTab0: {
    loading: false,
    dropDownData: {},
    chartData: {},
    loaded: false,
    chartLoaded: false,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ORDER_REPORTS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: true,
          loaded: false,
          chartLoaded: false,
        },
      };
    case ORDER_REPORTS_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          dropDownData: action.payload,
          loaded: true,
        },
      };
    case ORDER_REPORTS_DROPDOWN_DATA_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
          loaded: true,
        },
      };
    case LOAD_CHART_DATA_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          chartData: action.payload,
          loaded: true,
          chartLoaded: true,
        },
      };
    case LOAD_CHART_DATA_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          loaded: true,
          chartLoaded: false,
        },
      };
    default:
      return state;
  }
}
function getOrderReportsDropdownData(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: ORDER_REPORTS, tabKey });
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'ORDER_REPORTS_DROPDOWN_DATA',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode === '200') {
          dispatch({
            type: ORDER_REPORTS_DROPDOWN_DATA_SUCCESS,
            payload: data.result,
            tabKey,
          });
          return data;
        } else {
          dispatch({
            type: ORDER_REPORTS_DROPDOWN_DATA_FAIL,
            payload: 'Something went wrong!',
            tabKey,
          });
        }
      });
  };
}
const gettingLabelValue = (value) => {
  if (value != undefined) {
    return value.toFixed(0);
  } else {
    return '';
  }
};
const getPieYaxis = (datum) => {
  let yAxisValue = 0;
  if (datum['count']) {
    yAxisValue = datum['count'];
  } else if (datum['volume']) {
    yAxisValue = datum['count'];
  } else {
    yAxisValue = datum['price'];
  }
  return yAxisValue.toFixed(0);
};
const getReportHeight = (value) => {
  let subLabel = {
    'Top 10 Customer Orders Count': 450,
    'Top 10 Customer Orders by Volume': 450,
    'Top 10 Customers by Ext Value': 450,
    'Customer Orders Trending Chart': 450,
    'Open Orders Revenue Projection': 450,
    'Customer Comparison by Orders': 450,
    'Customer Comparison by Ext Value': 450,
  };
  return subLabel[value];
};
const returnContent = (datum, yKey) => {
  return {
    content:
      datum[yKey] != undefined ? datum[yKey].toFixed(0) : getPieYaxis(datum),
  };
};
const getSeriesForLabel = (details, series) => {
  let formSeries = [];
  series.map((data) => {
    if (details.reportName.indexOf('Comparison') > -1) {
      formSeries.push({
        ...data,
        tooltip: {
          renderer: function ({ datum, xKey, yKey }) {
            return returnContent(datum, yKey);
          },
        },
      });
    } else {
      formSeries.push({
        ...data,
        label: {
          formatter: ({ itemId, value }) => {
            return gettingLabelValue(value);
          },
        },
        tooltip: {
          renderer: function ({ datum, xKey, yKey }) {
            return returnContent(datum, yKey);
          },
        },
      });
    }
  });
  return formSeries;
};
function loadChartApi(tabKey, details) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: ORDER_REPORTS, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'LOAD_CHART',
            details: {
              email: Cookie.get('email'),
              chartDetails: details,
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/CDS/GET_LOAD_CHART`, currentTime);
        const { data } = response;
        let axes = [];
        if (
          details.reportName == 'Top 10 Customer Orders Count' &&
          details.chartType == 'bar'
        ) {
          axes = [
            {
              type: 'number',
              position: 'bottom',
              title: {
                text: 'Orders Count',
                enabled: true,
              },
            },
            {
              type: 'category',
              position: 'left',
              title: {
                text: 'Customer Name',
                enabled: true,
              },
            },
          ];
        } else if (
          details.reportName == 'Top 10 Customer Orders by Volume' &&
          details.chartType == 'bar'
        ) {
          axes = [
            {
              type: 'category',
              position: 'bottom',
              title: {
                text: 'Customer Name',
                enabled: true,
              },
            },
            {
              type: 'number',
              position: 'left',
              title: {
                text: 'Orders Volume',
                enabled: true,
              },
            },
          ];
        } else if (
          details.reportName == 'Top 10 Customers by Ext Value' &&
          details.chartType == 'bar'
        ) {
          axes = [
            {
              type: 'category',
              position: 'bottom',
              title: {
                text: 'Customer Name',
                enabled: true,
              },
            },
            {
              type: 'number',
              position: 'left',
              title: {
                text: 'Orders Price ($)',
                enabled: true,
              },
              label: {
                formatter: (params) => {
                  return '$' + params.value;
                },
              },
            },
          ];
        } else if (
          details.reportName == 'Customer Orders Trending Chart' ||
          details.reportName == 'Customer Comparison by Orders'
        ) {
          axes = [
            {
              type: 'category',
              position: 'bottom',
              title: {
                text: 'Month',
                enabled: true,
              },
            },
            {
              type: 'number',
              position: 'left',
              title: {
                text: 'Orders Count',
                enabled: true,
              },
            },
          ];
        } else {
          axes = [
            {
              type: 'category',
              position: 'bottom',
              title: {
                text: 'Month',
                enabled: true,
              },
            },
            {
              type: 'number',
              position: 'left',
              title: {
                text: 'Orders Price ($)',
                enabled: true,
              },
              label: {
                formatter: (params) => {
                  return '$' + params.value;
                },
              },
            },
          ];
        }
        if (
          data &&
          data.statusCode === '200' &&
          data.result.series.length > 0
        ) {
          let formSeries = getSeriesForLabel(details, data.result.series);
          const datachart = {
            ...data.result,
            series: formSeries,
            axes: axes,
            height: getReportHeight(details.reportName),
          };
          dispatch({
            type: LOAD_CHART_DATA_SUCCESS,
            payload: datachart,
            tabKey,
          });
          return data;
        } else {
          dispatch({
            type: LOAD_CHART_DATA_FAIL,
            payload: 'Something went wrong!',
            tabKey,
          });
          message.error(
            'Report data is not available for the selected customer!'
          );
        }
      });
  };
}
export { getOrderReportsDropdownData, loadChartApi };
